<template>
  <TableTransactions
    store="accounting.transactions"
    class="mb-4"
    :default_filter="default_filter"
  />
</template>

<script>
import TableTransactions from "../components/TableTransactions.vue";

export default {
  components: { TableTransactions },
  computed: {
    default_filter() {
      return {
        sort: "-updated_at",
      };
    },
  },
};
</script>
